'use strict';

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var BaseRegression = require('ml-regression-base');
var BaseRegression__default = _interopDefault(BaseRegression);
var SimpleLinearRegression = _interopDefault(require('ml-regression-simple-linear'));

class ExponentialRegression extends BaseRegression__default {
    constructor(x, y) {
        super();
        if (x === true) {
            this.A = y.A;
            this.B = y.B;
        } else {
            BaseRegression.checkArrayLength(x, y);
            regress(this, x, y);
        }
    }

    _predict(input) {
        return this.B * Math.exp(input * this.A);
    }

    toJSON() {
        return {
            name: 'exponentialRegression',
            A: this.A,
            B: this.B
        };
    }

    toString(precision) {
        return 'f(x) = ' + BaseRegression.maybeToPrecision(this.B, precision) + ' * e^(' + BaseRegression.maybeToPrecision(this.A, precision) + ' * x)';
    }

    toLaTeX(precision) {
        if (this.A >= 0) {
            return 'f(x) = ' + BaseRegression.maybeToPrecision(this.B, precision) + 'e^{' + BaseRegression.maybeToPrecision(this.A, precision) + 'x}';
        } else {
            return 'f(x) = \\frac{' + BaseRegression.maybeToPrecision(this.B, precision) + '}{e^{' + BaseRegression.maybeToPrecision(-this.A, precision) + 'x}}';
        }

    }

    static load(json) {
        if (json.name !== 'exponentialRegression') {
            throw new TypeError('not a exponential regression model');
        }
        return new ExponentialRegression(true, json);
    }
}

function regress(er, x, y) {
    const n = x.length;
    const yl = new Array(n);
    for (let i = 0; i < n; i++) {
        yl[i] = Math.log(y[i]);
    }

    const linear = new SimpleLinearRegression(x, yl);
    er.A = linear.slope;
    er.B = Math.exp(linear.intercept);
}

module.exports = ExponentialRegression;
