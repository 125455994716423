'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fromTriangles = fromTriangles;

var _inverse = require('./inverse');

var _transform = require('./transform');

var _smoothMatrix = require('./smoothMatrix');

/**
 * Returns a matrix that transforms a triangle t1 into another triangle t2, or throws an exception if it is impossible.
 * @param t1 {Array.<{x: number, y: number}> | Array.<Array<number>>} an array of points containing the three points for the first triangle
 * @param t2 {Array.<{x: number, y: number}> | Array.<Array<number>>} an array of points containing the three points for the second triangle
 * @returns {{a: number, b: number, c: number, e: number, d: number, f: number}} Affine matrix which transforms t1 to t2
 * @throws Exception if the matrix becomes not invertible
 */
function fromTriangles(t1, t2) {
  // point p = first point of the triangle
  var px1 = t1[0].x != null ? t1[0].x : t1[0][0];
  var py1 = t1[0].y != null ? t1[0].y : t1[0][1];
  var px2 = t2[0].x != null ? t2[0].x : t2[0][0];
  var py2 = t2[0].y != null ? t2[0].y : t2[0][1];

  // point q = second point of the triangle
  var qx1 = t1[1].x != null ? t1[1].x : t1[1][0];
  var qy1 = t1[1].y != null ? t1[1].y : t1[1][1];
  var qx2 = t2[1].x != null ? t2[1].x : t2[1][0];
  var qy2 = t2[1].y != null ? t2[1].y : t2[1][1];

  // point r = third point of the triangle
  var rx1 = t1[2].x != null ? t1[2].x : t1[2][0];
  var ry1 = t1[2].y != null ? t1[2].y : t1[2][1];
  var rx2 = t2[2].x != null ? t2[2].x : t2[2][0];
  var ry2 = t2[2].y != null ? t2[2].y : t2[2][1];

  var r1 = {
    a: px1 - rx1,
    b: py1 - ry1,
    c: qx1 - rx1,
    d: qy1 - ry1,
    e: rx1,
    f: ry1
  };
  var r2 = {
    a: px2 - rx2,
    b: py2 - ry2,
    c: qx2 - rx2,
    d: qy2 - ry2,
    e: rx2,
    f: ry2
  };

  var inverseR1 = (0, _inverse.inverse)(r1);
  var affineMatrix = (0, _transform.transform)([r2, inverseR1]);

  // round the matrix elements to smooth the finite inversion
  return (0, _smoothMatrix.smoothMatrix)(affineMatrix);
}