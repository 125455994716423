'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var FFT = _interopDefault(require('fft.js'));
var nextPOT = _interopDefault(require('next-power-of-two'));

function directConvolution(input, kernel, output) {
    if (output === undefined) {
        const length = input.length + kernel.length - 1;
        output = new Array(length);
    }
    fill(output);
    for (var i = 0; i < input.length; i++) {
        for (var j = 0; j < kernel.length; j++) {
            output[i + j] += input[i] * kernel[j];
        }
    }
    return output;
}

function fill(array) {
    for (var i = 0; i < array.length; i++) {
        array[i] = 0;
    }
}

function fftConvolution(input, kernel) {
    const resultLength = input.length + kernel.length - 1;
    const fftLength = nextPOT(resultLength);

    const fft = new FFT(fftLength);

    const {output: fftKernel, input: result} = createPaddedFFt(kernel, fft, fftLength);
    const {output: fftInput} = createPaddedFFt(input, fft, fftLength);

    // reuse arrays
    const fftConv = fftInput;
    const conv = fftKernel;
    for (var i = 0; i < fftConv.length; i += 2) {
        const tmp = fftInput[i] * fftKernel[i] - fftInput[i + 1] * fftKernel[i + 1];
        fftConv[i + 1] = fftInput[i] * fftKernel[i + 1] + fftInput[i + 1] * fftKernel[i];
        fftConv[i] = tmp;
    }
    fft.inverseTransform(conv, fftConv);
    return fft.fromComplexArray(conv, result).slice(0, resultLength);
}

function createPaddedFFt(data, fft, length) {
    const input = new Array(length);
    var i = 0;
    for (; i < data.length; i++) {
        input[i] = data[i];
    }
    for (;i < length; i++) {
        input[i] = 0;
    }
    const fftInput = fft.toComplexArray(input);
    const output = fft.createComplexArray();
    fft.transform(output, fftInput);
    return {
        output,
        input,
        fftInput
    };
}

exports.directConvolution = directConvolution;
exports.fftConvolution = fftConvolution;
