"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "default", {
  enumerable: true,
  get: function () {
    return _Image.default;
  }
});
Object.defineProperty(exports, "Image", {
  enumerable: true,
  get: function () {
    return _Image.default;
  }
});
Object.defineProperty(exports, "Stack", {
  enumerable: true,
  get: function () {
    return _Stack.default;
  }
});
Object.defineProperty(exports, "Shape", {
  enumerable: true,
  get: function () {
    return _Shape.default;
  }
});
Object.defineProperty(exports, "Worker", {
  enumerable: true,
  get: function () {
    return _worker.default;
  }
});
exports.Kernel = exports.Static = void 0;

var _Image = _interopRequireDefault(require("./image/Image"));

var Kernel = _interopRequireWildcard(require("./kernel/kernel"));

exports.Kernel = Kernel;

var _greyAlgorithms = require("./image/transform/greyAlgorithms");

var _thresholdAlgorithms = require("./image/transform/mask/thresholdAlgorithms");

var _Stack = _interopRequireDefault(require("./stack/Stack"));

var _Shape = _interopRequireDefault(require("./util/Shape"));

var _worker = _interopRequireDefault(require("./worker/worker"));

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {}; if (desc.get || desc.set) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const Static = {
  grey: _greyAlgorithms.names,
  threshold: _thresholdAlgorithms.names
};
exports.Static = Static;