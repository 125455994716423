module.exports={
  "_args": [
    [
      "has-own@1.0.0",
      "/home/guest/mrz-scanner.alsenet.git"
    ]
  ],
  "_from": "has-own@1.0.0",
  "_id": "has-own@1.0.0",
  "_inBundle": false,
  "_integrity": "sha1-MGIkbjHP2Iepph7m04ylcok3jNE=",
  "_location": "/has-own",
  "_phantomChildren": {},
  "_requested": {
    "type": "version",
    "registry": true,
    "raw": "has-own@1.0.0",
    "name": "has-own",
    "escapedName": "has-own",
    "rawSpec": "1.0.0",
    "saveSpec": null,
    "fetchSpec": "1.0.0"
  },
  "_requiredBy": [
    "/image-js"
  ],
  "_resolved": "https://registry.npmjs.org/has-own/-/has-own-1.0.0.tgz",
  "_spec": "1.0.0",
  "_where": "/home/guest/mrz-scanner.alsenet.git",
  "author": {
    "name": "Aaron Heckmann",
    "email": "aaron.heckmann+github@gmail.com"
  },
  "bugs": {
    "url": "https://github.com/pebble/has-own/issues"
  },
  "description": "A safer .hasOwnProperty() - hasOwn(name, obj)",
  "devDependencies": {
    "mocha": "^1.21.0"
  },
  "homepage": "https://github.com/pebble/has-own/",
  "license": "MIT",
  "main": "index.js",
  "name": "has-own",
  "repository": {
    "type": "git",
    "url": "git://github.com/pebble/has-own.git"
  },
  "scripts": {
    "test": "make test"
  },
  "version": "1.0.0"
}
