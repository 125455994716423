'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var mlRegressionSimpleLinear = _interopDefault(require('ml-regression-simple-linear'));
var PolynomialRegression = _interopDefault(require('ml-regression-polynomial'));
var mlRegressionExponential = _interopDefault(require('ml-regression-exponential'));
var mlRegressionPower = _interopDefault(require('ml-regression-power'));
var mlRegressionMultivariateLinear = _interopDefault(require('ml-regression-multivariate-linear'));
var BaseRegression = require('ml-regression-base');
var BaseRegression__default = _interopDefault(BaseRegression);
var mlMatrix = require('ml-matrix');
var Kernel = _interopDefault(require('ml-kernel'));
var mlRegressionTheilSen = _interopDefault(require('ml-regression-theil-sen'));
var mlRegressionRobustPolynomial = _interopDefault(require('ml-regression-robust-polynomial'));

/*
 * Function that calculate the potential fit in the form f(x) = A*x^M
 * with a given M and return de A coefficient.
 *
 * @param {Vector} X - Vector of the x positions of the points.
 * @param {Vector} Y - Vector of the x positions of the points.
 * @param {Number} M - The exponent of the potential fit.
 * @return {Number} A - The A coefficient of the potential fit.
 */
class PotentialRegression extends BaseRegression__default {
    /**
     * @constructor
     * @param x: Independent variable
     * @param y: Dependent variable
     * @param M
     */
    constructor(x, y, M) {
        super();
        if (x === true) { // reloading model
            this.A = y.A;
            this.M = y.M;
        } else {
            var n = x.length;
            if (n !== y.length) {
                throw new RangeError('input and output array have a different length');
            }

            var linear = new PolynomialRegression(x, y, [M]);
            this.A = linear.coefficients[0];
            this.M = M;
        }
    }

    _predict(x) {
        return this.A * Math.pow(x, this.M);
    }

    toJSON() {
        return {
            name: 'potentialRegression',
            A: this.A,
            M: this.M
        };
    }

    toString(precision) {
        return 'f(x) = ' + BaseRegression.maybeToPrecision(this.A, precision) + ' * x^' + this.M;
    }

    toLaTeX(precision) {
        if (this.M >= 0) {
            return 'f(x) = ' + BaseRegression.maybeToPrecision(this.A, precision) + 'x^{' + this.M + '}';
        } else {
            return 'f(x) = \\frac{' + BaseRegression.maybeToPrecision(this.A, precision) + '}{x^{' + (-this.M) + '}}';
        }
    }

    static load(json) {
        if (json.name !== 'potentialRegression') {
            throw new TypeError('not a potential regression model');
        }
        return new PotentialRegression(true, json);
    }
}

const defaultOptions = {
    lambda: 0.1,
    kernelType: 'gaussian',
    kernelOptions: {},
    computeCoefficient: false
};

// Implements the Kernel ridge regression algorithm.
// http://www.ics.uci.edu/~welling/classnotes/papers_class/Kernel-Ridge.pdf
class KernelRidgeRegression extends BaseRegression__default {
    constructor(inputs, outputs, options) {
        super();
        if (inputs === true) { // reloading model
            this.alpha = outputs.alpha;
            this.inputs = outputs.inputs;
            this.kernelType = outputs.kernelType;
            this.kernelOptions = outputs.kernelOptions;
            this.kernel = new Kernel(outputs.kernelType, outputs.kernelOptions);
        } else {
            options = Object.assign({}, defaultOptions, options);

            const kernelFunction = new Kernel(options.kernelType, options.kernelOptions);
            const K = kernelFunction.compute(inputs);
            const n = inputs.length;
            K.add(mlMatrix.Matrix.eye(n, n).mul(options.lambda));

            this.alpha = mlMatrix.solve(K, outputs);
            this.inputs = inputs;
            this.kernelType = options.kernelType;
            this.kernelOptions = options.kernelOptions;
            this.kernel = kernelFunction;
        }
    }

    _predict(newInputs) {
        return this.kernel.compute([newInputs], this.inputs).mmul(this.alpha)[0];
    }

    toJSON() {
        return {
            name: 'kernelRidgeRegression',
            alpha: this.alpha,
            inputs: this.inputs,
            kernelType: this.kernelType,
            kernelOptions: this.kernelOptions
        };
    }

    static load(json) {
        if (json.name !== 'kernelRidgeRegression') {
            throw new TypeError('not a KRR model');
        }
        return new KernelRidgeRegression(true, json);
    }
}

const defaultOptions$1 = {
    order: 2
};
// Implements the Kernel ridge regression algorithm.
// http://www.ics.uci.edu/~welling/classnotes/papers_class/Kernel-Ridge.pdf
class PolynomialFitRegression2D extends BaseRegression__default {
    /**
     * Constructor for the 2D polynomial fitting
     *
     * @param inputs
     * @param outputs
     * @param options
     * @constructor
     */
    constructor(inputs, outputs, options) {
        super();
        if (inputs === true) { // reloading model
            this.coefficients = mlMatrix.Matrix.columnVector(outputs.coefficients);
            this.order = outputs.order;
            if (outputs.r) {
                this.r = outputs.r;
                this.r2 = outputs.r2;
            }
            if (outputs.chi2) {
                this.chi2 = outputs.chi2;
            }
        } else {
            options = Object.assign({}, defaultOptions$1, options);
            this.order = options.order;
            this.coefficients = [];
            this.X = inputs;
            this.y = outputs;

            this.train(this.X, this.y, options);
        }
    }

    /**
     * Function that fits the model given the data(X) and predictions(y).
     * The third argument is an object with the following options:
     * * order: order of the polynomial to fit.
     *
     * @param {Matrix} X - A matrix with n rows and 2 columns.
     * @param {Matrix} y - A vector of the prediction values.
     */
    train(X, y) {
        if (!mlMatrix.Matrix.isMatrix(X)) X = new mlMatrix.Matrix(X);
        if (!mlMatrix.Matrix.isMatrix(y)) y = mlMatrix.Matrix.columnVector(y);

        if (y.rows !== X.rows) {
            y = y.transpose();
        }

        if (X.columns !== 2) {
            throw new RangeError('You give X with ' + X.columns + ' columns and it must be 2');
        }
        if (X.rows !== y.rows) {
            throw new RangeError('X and y must have the same rows');
        }

        var examples = X.rows;
        var coefficients = ((this.order + 2) * (this.order + 1)) / 2;
        this.coefficients = new Array(coefficients);

        var x1 = X.getColumnVector(0);
        var x2 = X.getColumnVector(1);

        var scaleX1 = 1.0 / x1.clone().apply(abs).max();
        var scaleX2 = 1.0 / x2.clone().apply(abs).max();
        var scaleY = 1.0 / y.clone().apply(abs).max();

        x1.mulColumn(0, scaleX1);
        x2.mulColumn(0, scaleX2);
        y.mulColumn(0, scaleY);

        var A = new mlMatrix.Matrix(examples, coefficients);
        var col = 0;

        for (var i = 0; i <= this.order; ++i) {
            var limit = this.order - i;
            for (var j = 0; j <= limit; ++j) {
                var result = powColVector(x1, i).mulColumnVector(powColVector(x2, j));
                A.setColumn(col, result);
                col++;
            }
        }

        var svd = new mlMatrix.SVD(A.transpose(), {
            computeLeftSingularVectors: true,
            computeRightSingularVectors: true,
            autoTranspose: false
        });

        var qqs = mlMatrix.Matrix.rowVector(svd.diagonal);
        qqs = qqs.apply(function (i, j) {
            if (this[i][j] >= 1e-15) this[i][j] = 1 / this[i][j];
            else this[i][j] = 0;
        });

        var qqs1 = mlMatrix.Matrix.zeros(examples, coefficients);
        for (i = 0; i < coefficients; ++i) {
            qqs1[i][i] = qqs[0][i];
        }

        qqs = qqs1;

        var U = svd.rightSingularVectors;
        var V = svd.leftSingularVectors;

        this.coefficients = V.mmul(qqs.transpose()).mmul(U.transpose()).mmul(y);

        col = 0;

        for (i = 0; i <= coefficients; ++i) {
            limit = this.order - i;
            for (j = 0; j <= limit; ++j) {
                this.coefficients[col][0] = (this.coefficients[col][0] * Math.pow(scaleX1, i) * Math.pow(scaleX2, j)) / scaleY;
                col++;
            }
        }
    }

    _predict(newInputs) {
        var x1 = newInputs[0];
        var x2 = newInputs[1];

        var y = 0;
        var column = 0;

        for (var i = 0; i <= this.order; i++) {
            for (var j = 0; j <= this.order - i; j++) {
                y += Math.pow(x1, i) * (Math.pow(x2, j)) * this.coefficients[column][0];
                column++;
            }
        }

        return y;
    }

    toJSON() {
        return {
            name: 'polyfit2D',
            order: this.order,
            coefficients: this.coefficients
        };
    }

    static load(json) {
        if (json.name !== 'polyfit2D') {
            throw new TypeError('not a polyfit2D model');
        }
        return new PolynomialFitRegression2D(true, json);
    }
}

/**
 * Function that given a column vector return this: vector^power
 *
 * @param x - Column vector.
 * @param power - Pow number.
 * @return {Suite|Matrix}
 */
function powColVector(x, power) {
    var result = x.clone();
    for (var i = 0; i < x.rows; ++i) {
        result[i][0] = Math.pow(result[i][0], power);
    }
    return result;
}

/**
 * Function to use in the apply method to get the absolute value
 * of each element of the matrix
 *
 * @param i - current row.
 * @param j - current column.
 */
function abs(i, j) {
    this[i][j] = Math.abs(this[i][j]);
}

const NLR = {
    PotentialRegression
};

exports.NLR = NLR;
exports.NonLinearRegression = NLR;
exports.SimpleLinearRegression = mlRegressionSimpleLinear;
exports.SLR = mlRegressionSimpleLinear;
exports.PolynomialRegression = PolynomialRegression;
exports.ExponentialRegression = mlRegressionExponential;
exports.PowerRegression = mlRegressionPower;
exports.MultivariateLinearRegression = mlRegressionMultivariateLinear;
exports.KernelRidgeRegression = KernelRidgeRegression;
exports.KRR = KernelRidgeRegression;
exports.PolinomialFitting2D = PolynomialFitRegression2D;
exports.TheilSenRegression = mlRegressionTheilSen;
exports.RobustPolynomialRegression = mlRegressionRobustPolynomial;
