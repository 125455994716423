'use strict';

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var sum = _interopDefault(require('ml-array-sum'));

/**
 * Computes the mean of the given values
 * @param {Array<number>} input
 * @return {number}
 */
function mean(input) {
  return sum(input) / input.length;
}

module.exports = mean;
